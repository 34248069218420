* {
  /* remove all default margin and padding */
  margin: 0;
  padding: 0;
  /* activate box model -> only then widht and height prop works as expected*/
  box-sizing: border-box;
  font-family: "Coiny", sans-serif;
}

button,
input {
  all: none;
}

/* google symbols */
.material-symbols-outlined {
  font-size: 40px;
  border-radius: 50%;
  font-weight: 400;
}
.material-symbols-outlined:hover {
  cursor: pointer;
  color: #097ec2;
  font-weight: 600;
}

.active-icon {
  color: #fff;
  background-color: #097ec2;
  box-shadow: 3px 3px 3px #000;
}

.active-icon:hover {
  color: #fff;
  background-color: #097ec2;
  box-shadow: 3px 3px 3px #000;
}

.material-symbols-outlined:active {
  cursor: pointer;
  color: #fff;
  background-color: #097ec2;
  box-shadow: 3px 3px 3px #000;
}

body {
  zoom: 1; /* Ensures layout starts at 100% */
  background-color: #ddd;
}

h1 {
  font-size: 40px;
  text-transform: uppercase;

  color: #097fc2;
  font-weight: 800;
}

a {
  font-family: "Coiny", sans-serif;
  font-weight: 500;
}

/* start screen and login modal*/
.startscreen {
  background-color: #000;
  position: absolute;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.startscreen img {
  width: 100%;
  height: auto;
}

.LoginFrame {
  position: absolute;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.LoginAndRegister {
  padding: 10px 20px;
  background-image: linear-gradient(#ffffff67, #ffffff59, #ffffff00);
  /* background-color: #ffffff59; */
  border: 2px solid #ffffff8a;
  border-radius: 30px;

  width: 75%;
  max-width: 500px;
  height: 70%;

  display: grid;
  grid-template-rows: 1fr 6fr 1fr;
}

.LoginAndRegister .Login-Header {
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  column-gap: 2px;
  justify-content: start;
  align-items: flex-end;

  /* background-color: rgb(10, 209, 209); */
}
.LoginAndRegister .Login-Header button {
  border: none;
  border-bottom: 1px solid #fff;
  height: 35px;
  width: 45%;
  border-radius: 20px 20px 0px 0px;
}
.LoginAndRegister .Login-Body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 20px;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 20px;
  background-color: #097ec279;
  /* padding: 20px; */

  margin-bottom: 20px;
}

.LoginAndRegister .Login-Body input {
  font-size: medium;
  padding-left: 20px;
  border: 1px solid #000;
  height: 40px;
  width: 90%;
  max-width: 250px;
  border-radius: 50px;
}

.LoginAndRegister .Login-Body img {
  height: 60px;
}

.Login-Footer {
  margin-top: 20px;
  padding: 0px 20px;
  display: flex;
  justify-content: flex-end;
  /* background-color: rgba(163, 170, 170, 0.26); */
}

.Login-Footer button {
  border: 1px solid #097fc2;
  color: #097fc2;
  height: 50px;
  width: 100px;
  border-radius: 50px;
}

.Login-Footer button:active {
  background-color: #097fc2;
  color: #fff;
  height: 50px;
  width: 100px;
  border-radius: 50px;
}

@media only screen and (max-height: 500px) and (orientation: landscape) {
  .LoginAndRegister .Login-Body {
    flex-direction: row;
  }

  .LoginAndRegister {
    width: 90%;
    height: 75%;
  }

  .LoginAndRegister .Login-Body input {
    height: 25px;
  }
  .Login-Footer {
    margin-top: 10px;
  }

  .Login-Footer button {
    height: 40px;
  }
}
@media only screen and (max-height: 400px) and (orientation: landscape) {
  .LoginAndRegister {
    width: 90%;
    height: 85%;
  }
  .Login-Footer {
    margin-top: 5px;
  }

  .Login-Footer button {
    height: 30px;
  }
}

.floating-mstcon {
  z-index: 100;
  position: fixed;
  top: 50%;
  right: 2%;
  transition: 0.75s;
}

.floating-mstcon img {
  width: 60px;
}

.floating-mstcon:hover {
  transform: scale(2);
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .floating-mstcon {
    top: 90%;
  }
}

/* https://css-tricks.com/snippets/css/complete-guide-grid/ */
.container-grid-1 {
  height: 100vh; /* Fallback for older browsers */
  height: 100dvh; /* Use dynamic viewport height if supported */
  width: 100vw;

  display: grid;
  grid-template-columns: 40px auto;
  grid-template-rows: 40px auto;
  row-gap: 2px;
  column-gap: 2px;
  grid-template-areas:
    "header header"
    "sidebar main";
}
/* For browsers that support modern viewport units */
@supports (height: 100dvh) {
  .container {
    height: 100dvh;
  }
}

.header {
  grid-area: header;
  /* max-width: 1500px; */
  margin: 0;

  background-color: #097ec2;
  /* background-color: #000000; */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  color: white;

  display: flex;
  /* align verticaly */
  align-items: center;
  /* align horicontaly */
  justify-content: flex-end;

  /* position: fixed; */
  width: 100%;
  z-index: 9999;
  padding: 0px 40px;
  /* margin-bottom: 40px; */
}

.sidebar {
  grid-area: sidebar;
  z-index: 1;
  padding-top: 20px;
  padding-bottom: 90px;
  /* padding-left: 5px; */
  padding-right: 5px;
  width: 100%;
  height: 100%;

  /* position: fixed; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  background-color: #fff;
  font-size: 0px;
  color: #00000000;

  transition: 0.1s;
  border-radius: 0px 20px 20px 0px;
  border: #bbb 1px solid;
  border-left: none;
}

.disabled-hover {
  pointer-events: none;
}

.sidebar img {
  width: 40px;
  transition: 0.1s;
}

.sidebar img:hover {
  transform: scale(1.2);
}

.sidebar .logo {
  color: #000;
}

.sidebar .logo img {
  width: 105%;
  max-width: 75px;
  margin-bottom: 20px;
}

#logoutIcon {
  width: 0px;
}

.sidebar:hover {
  width: auto;
  min-width: 200px;
  font-size: medium;
  color: #000;
  padding-left: 10px;
  #logoutIcon {
    width: 40px;
  }
}

.sidebar:hover + .Function-Screen {
  padding-left: 200px;
}

.sidebar .rows {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  margin-left: 10px;
  margin-right: 10px;
}

.sidebar .sidebar-row {
  display: flex;
  flex-direction: row;

  column-gap: 20px;
  align-items: center;
  transition: 0.25s;
  padding-right: 20px;
  border-radius: 10px;
}

.sidebar .sidebar-row:hover {
  background-color: #1d2c359c;
  color: #fff;
  cursor: pointer;
}

.sidebar .sidebar-row img {
  filter: opacity(0.3);
}

.sidebar .sidebar-row:hover img {
  filter: invert(1);
}

.sidebar .user {
  margin-top: 20px;
  width: 100%;
  display: flex;
  column-gap: 20px;
  justify-content: space-between;
  align-items: center;
}
.sidebar .user img:hover {
  cursor: pointer;
}

@media only screen and (max-height: 500px) and (orientation: landscape) {
  .sidebar .logo {
  }
  .sidebar .logo img {
    max-width: 30px;
    margin-bottom: 10px;
  }
  .sidebar .rows {
    row-gap: 10px;
  }
  .sidebar img {
    width: 30px;
  }
}

/* Main function screen */
.Function-Screen {
  grid-area: main;
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 20px;
  border: 1px #bbb solid;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 60px auto;
  grid-template-areas: "Function-Screen-header" "Function-Screen-main";
}
.Function-Screen-header {
  grid-area: Function-Screen-header;

  /* background-image: linear-gradient(180deg, #1d2c359c, #fff); */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Function-Screen-main {
  padding-top: 20px;
  grid-area: Function-Screen-main;
  /* background-color: rgb(206, 252, 132); */
}

/* #################################################################### */
/* Table */
/* https://dev.to/dcodeyt/creating-beautiful-html-tables-with-css-428l */

.tableSearchBar,
.tableSearchBar form {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 40px;
}

.tableSearchBar-Input,
.tableSearchBar-Buttons {
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  align-items: center;
  align-content: center;
}
.tableSearchBar input,
select,
button {
  font-size: medium;
  color: #000;
  padding: 0px 20px;
  max-width: 175px;
  height: 40px;
  border-radius: 100px;
}
.tableSearchBar select:hover {
  font-size: medium;
  /* color: #097ec2; */
  border: #097ec2 3px solid;
}

.tableSearchBar button {
  height: 30px;
  font-size: medium;
  color: #fff;
  background-color: #097ec2;

  border: none;
  border-radius: 100px;
}

.tableSearchBar button:active {
  box-shadow: 3px 3px 3px #000;
}

@media only screen and (orientation: portrait) {
  .tableSearchBar form,
  .tableSearchBar-Input {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .tableSearchBar-Input select {
    width: auto;
  }
}

.FrameAroundTable {
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  max-width: 80vw;
  border-radius: 20px;
  padding-bottom: 10px;
  margin-bottom: 40px;
}

.styled-table {
  border-collapse: collapse;
  table-layout: auto;

  overflow-x: scroll;
  width: 80vw;
  overflow-y: scroll;
  height: auto;
  text-wrap: pretty;

  /* display: block; */
  border-radius: 15px;
  /* margin: 25px 0; */
  font-size: 0.9em;
  font-family: sans-serif;
  /* width: 75vw; */

  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #1d2c359c;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 10px 5px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}
.styled-table tbody tr:hover,
tr:nth-of-type(even):hover {
  background-color: #097ec20a;
  color: #097ec2;
  cursor: pointer;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #1d2c359c;
}

.styled-table tbody td.active-row {
  font-weight: bold;
  color: #1d2c359c;
}

/* @media only screen and (orientation: portrait) {
} */

/* EDIT */

/* .modalFormUserEdit {
  display: flex;
  flex-direction: column;
} */

.modalFormUserEdit button {
  height: 30px;
  font-size: medium;
  color: #fff;
  background-color: #097ec2;

  border: none;
  border-radius: 100px;
}

.modalFormUserEdit button:active {
  box-shadow: 3px 3px 3px #000;
}

/* Add new user */

.addNewUser {
  margin-bottom: 20px;
}

.addNewUser-Header {
  padding-top: 10px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  align-items: center;
}

.addNewUser button {
  height: 30px;
  font-size: medium;
  color: #fff;
  background-color: #097ec2;

  border: none;
  border-radius: 100px;
}

.addNewUser button:active {
  box-shadow: 3px 3px 3px #000;
}

.addNewUser-Main {
  display: flex;
  flex-direction: column;
  column-gap: 10px;
  row-gap: 5px;
  /* justify-content: flex-start; */
  border-radius: 20px;
}

.addNewUser-Main-Line {
  display: grid;
  grid-template-columns: 120px 250px;
}

/* #################################################################### */
/* DASHBOARD */

@media only screen and (orientation: portrait) {
  .resp-plotlyplot-width {
    width: 80vw;
    height: 80vh;
  }
}

@media only screen and (orientation: landscape) {
  .resp-plotlyplot-width {
    width: 80vw;
    height: 80vh;
  }
}

/* #################################################################### */
/* FOOTER */
.footer {
  background-color: #000000;
  color: white;
  margin-top: 40px;
  padding: 5px;
  display: flex;
  /* align verticaly */
  align-items: center;
  /* align horicontal
  y */
  justify-content: space-between;
}

.footer button {
  color: white;
  /* no borders */
  width: 200px;
  border: none;
  font-family: "Coiny", sans-serif;
  line-height: 1;
  text-transform: uppercase;
  font-size: 16px;
  padding: 16px 0 13px;
  background-color: #000000;
  border-radius: 100px;

  /* transition: 1s; */
}

.footer button:hover {
  /* transform: scale(1.1); */
  /* text-decoration: underline; */
  background-color: #000000;
  font-weight: 600;
  /* cursor: grabbing; */
}
